import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { getArticleService } from "../services/blog.services";
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import moment from "moment";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  name: {
    lineHeight: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  image: {
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function Entry({ postName }) {
  const classes = useStyles();
  const [article, setArticle] = useState(null);

  
  
  const renderContent = (content, index) => {
    switch (content.type) {
      case "media":
        return (
          <Box my={4} key={`media${index}`}>
            <img src={content?.url} alt="" className={classes.image} loading="lazy" />
          </Box>
        );
      case "numberList":
        return (
          <ol start={content?.start ?? 1} key={index}>
            {content?.items?.map((item, index) => (
              <li key={index}>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontWeight: 100,
                  }}
                  key={index}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.text }} />
                </Typography>
              </li>
            ))}
          </ol>
        );
      case "bulletList":
        return (
          <ul key={index}>
            {content?.items?.map((item, index) => (
              <li key={index}>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontWeight: 100,
                  }}
                  key={index}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.text }} />
                </Typography>
              </li>
            ))}
          </ul>
        );
      case "table":
        return (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {content?.headers?.map((header, index) => (
                    <TableCell key={`${header}_${index}`}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {content?.rows?.map((row, index) => (
                  <TableRow
                    key={`${row}_${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {row.map((item, index) => (
                      <TableCell key={`${item}_${index}`} component="th" scope="row">
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      case "descriptionList":
        return (
          <dl>
            {content?.items?.map((item, index) =>
              item?.type === "dd" ? (
                <dd key={index}>
                  <Typography
                    style={{
                      textAlign: "justify",
                      fontWeight: 100,
                    }}
                    key={index}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.text }} />
                  </Typography>
                </dd>
              ) : (
                <dt key={index}>
                  <Typography
                    style={{
                      textAlign: "justify",
                      fontWeight: 100,
                    }}
                    key={index}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.text }} />
                  </Typography>
                </dt>
              )
            )}
          </dl>
        );
      case "subtitle":
        return (
          <Typography id={content.text} variant="h5" component="h2" style={{ fontWeight: 500 }} key={index}>
            {content.text}
          </Typography>
        );
      case "subtitle1":
        return (
          <Typography id={content.text} variant="h6" component="h3" style={{ fontWeight: 500 }} key={index}>
            {content.text}
          </Typography>
        );
      case "subtitle2":
        return (
          <Typography id={content.text} variant="h6" component="h4" style={{ fontWeight: 500 }} key={index}>
            {content.text}
          </Typography>
        );
      case "paragraph":
        return (
          <Typography
            style={{ fontWeight: 100, textAlign: "justify" }}
            key={index}
          >
            <span dangerouslySetInnerHTML={{ __html: content.text }} />
          </Typography>
        );
      default:
        return null;
    }
  };

  const getArticle = async () => {
    try {
      const { success, data } = await getArticleService(postName);

      if (success) {
        setArticle(data);
      }
    } catch (error) {
      console.log("getArticle -> error: ", error);
    }
  };

  useEffect(() => {
    getArticle();
  }, []);

  return article ? (
    <section>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ article?.metaTitulo ? article.metaTitulo : article.title }</title>
        <link rel="canonical" href={`https://belozmexico.com.mx/blog/${article.urlTitle}`} />
        <meta name="description" content={ article?.metaDescripcion ? article.metaDescripcion : article.intro } />
        <meta name="keywords" content={ article?.keywords} />
      </Helmet>
      <Container maxWidth="md">
        <Box py={10}>
          <Box textAlign="center" mb={5}>
            <Container maxWidth="sm">
              <Chip
                style={{ backgroundColor: "#FACE07" }}
                label={ moment(article.createdAt).format("DD/MM/YYYY") }
              />
              <Box my={4}>
                <Typography variant="h3" component="h1">
                  {article?.title}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar alt="" src={"./images/blog/author.png"} />
                <Box ml={2} textAlign="left">
                  <Typography
                    variant="subtitle1"
                    component="h2"
                    className={classes.name}
                  >
                    { article?.author ? article.author : 'Patricio Arrechavaleta' }
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    color="textSecondary"
                  >
                    { article?.role ? article.role : 'Especialista en Growth' }
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
          <Box className={classes.content}>
            <Typography variant="body2" color="textSecondary">
              <i>{article?.intro}</i>
            </Typography>
            <div
              style={{
                backgroundColor: "rgb(242, 242, 242)",
                padding: 16,
                borderRadius: 8,
              }}
            >
              <Typography variant="h6">Contenido</Typography>
              <section>
                {JSON.parse(article?.paragraphs)?.data &&
                  JSON.parse(article?.paragraphs)?.data?.length > 0 &&
                  JSON.parse(article?.paragraphs)?.data.map((item, idx) => {
                    if (item.type === "subtitle") {
                      return (
                        <Typography
                          key={idx}
                          textAlign="justify"
                          mb={2}
                          sx={{
                            fontWeight: 549,
                            paddingLeft: 1.5,
                            fontSize: "16px",
                          }}
                        >
                          <Link
                            href={`#${item.text}`}
                            underline="hover"
                            variant="body2"
                            color='textSecondary'
                          >
                            {`• ${item.text}`}
                          </Link>
                        </Typography>
                      );
                    }
                  })}
              </section>
            </div>
            {JSON.parse(article?.paragraphs)?.data?.map((paragraph, index) =>
              renderContent(paragraph, index)
            )}
          </Box>
        </Box>
      </Container>
    </section>
  ) : null;
}
