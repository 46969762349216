import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Footer, Help, Navbar } from "../components";

const useStyles = makeStyles((theme) => ({
  block: {
    marginBottom: theme.spacing(3),
  },
  image: {
    maxWidth: "100%",
  },
}));

export default function PrivacyPolicy(props) {
  const classes = useStyles();

  return (
    <section>
      <Container maxWidth="sm">
        <Box py={8}>
          <Box mb={4} textAlign="center">
            <Typography variant="overline" component="span">
              Última actualización Febrero 14, 2024
            </Typography>
            <Typography variant="h3" component="h2">
              Aviso{" "}
              <Typography
                variant="h3"
                component="span"
                style={{ fontWeight: 700, color: "#FACE07" }}
              >
                de privacidad
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              ABDZ COMERZA, S.A. DE C.V. ("Beloz"), con domicilio en Avenida
              Popocatépetl 510, Colonia Xoco, Benito Juárez, Benito Juárez,
              03330 Ciudad de México, CDMX, es una sociedad comprometida con la
              protección de datos personales y la privacidad de sus usuarios; en
              cumplimiento a la Ley Federal de Protección de Datos Personales en
              Posesión de los Particulares y su Reglamento, así como a los más
              altos estándares en materia de protección de datos y las
              disposiciones aplicables, pone a su disposición el siguiente Aviso
              de Privacidad.
              <br />
              <br />
              Al proporcionar cualquier tipo de información, incluyendo sus
              datos personales, usted expresamente:
            </Typography>
            <ol>
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph={true}
              >
                <li>
                  Acepta las condiciones contenidas en el presente Aviso de
                  Privacidad.
                </li>
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph={true}
              >
                <li>
                  Está de acuerdo en que la información proporcionada por usted
                  pueda almacenarse, usarse y, en general, tratarse para los
                  fines que se señalan más adelante, incluso para fines
                  comerciales y de promoción.
                </li>
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph={true}
              >
                <li>
                  Otorga a Beloz autorización para obtener, compilar, almacenar,
                  compartir, comunicar, transmitir y usar tal información de
                  cualquier manera o forma, de conformidad con las condiciones
                  establecidas en el presente Aviso de Privacidad y las leyes
                  aplicables.
                </li>
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                paragraph={true}
              >
                <li>
                  Autoriza a recibir cualquier tipo de comunicación por medio de
                  mensajes de texto SMS, correo electrónico, redes sociales,
                  aplicaciones de escritorio y/o móviles.
                </li>
              </Typography>
            </ol>
            {/* Uso */}
            <Box mt={4} mb={3}>
              <Typography variant="h5">1. IDENTIDAD DEL RESPONSABLE</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              ABDZ COMERZA, S.A. DE C.V.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">2. DOMICILIO DEL RESPONSABLE</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              Avenida Popocatépetl 510, Colonia Xoco, Benito Juárez, Benito
              Juárez, 03330 Ciudad de México
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">3. FINALIDADES</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              PRIMARIAS
              <li>Contacto</li>
              <li>Oferta de crédito</li>
              <li>Alta de cliente</li>
              <li>Elaborar y complementar el expediente de cliente</li>
              <li>Prestación de servicios materia de nuestro contrato</li>
              <li>Prevención de fraudes</li>
              <li>Cobranza</li>
              <li>Facturación</li>
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              SECUNDARIAS
              <li>Prospección comercial</li>
              <li>Mercadotecnía</li>
              <li>Notificarle sobre nuevos productos o servicios</li>
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">4. DATOS POR CATEGORIAS</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              Para las finalidades antes mencionadas, recabamos los siguientes
              datos proporcionados por usted.
              <br />
              <br />
              IDENTIFICACIÓN
              <br />
              <li>Nombre completo</li>
              <li>Domicilio</li>
              <li>Identificación oficial</li>
              <li>RFC</li>
              <li>Comprobante de domicilio</li>
              <li>
                Datos del dispositivo de acceso incluyendo el equipo así como
                las aplicaciones instaladas.
              </li>
              <br />
              CONTACTO
              <br />
              <li>Teléfono celular</li>
              <li>Teléfono fijo</li>
              <li>Correo electrónico</li>
              <li>Calendario en dispositivo de acceso</li>
              <br />
              FINANCIEROS
              <br />
              <li>Estatus crediticio</li>
              <br />
              Beloz no trata datos sensibles.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">5. TRANSFERENCIAS</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              Le comunicamos que Beloz comparte sus datos con proveedores de
              servicios relacionados con el objeto de la relación jurídica con
              Beloz. De igual forma, sus datos personales se comparten con los
              programadores encargados de llevar a cabo todas las gestiones de
              servicio y mantenimiento de los medios electrónicos, tales como,
              página de internet, aplicación móvil o cualquier otra plataforma
              autorizada por Beloz e indispensable para el apropiado desarrollo
              de sus medios de comunicación.
              <br />
              <br />
              Asimismo, Beloz compartirá sus datos personales cuando:
              <br />
              <li>
                Esté prevista en una Ley o Tratado de los que México sea parte.
              </li>
              <li>
                Sea efectuada a sociedades controladoras, subsidiarias o
                afiliadas bajo el control común de Beloz o como resultado
                inherente a la cesión de los derechos de Beloz.
              </li>
              <li>
                Sea precisa para el mantenimiento o cumplimiento de una relación
                jurídica entre Beloz y el Titular.
              </li>
              <li>
                Sea con base en los demás supuestos establecidos en el Artículo
                37 de la Ley Federal de Protección de Datos Personales en
                Posesión de los Particulares.
              </li>
              <li>
                Beloz ceda, transmita, transfiera, afecte, grave y/o negocie en
                cualquier forma, los derechos de crédito del contrato que sea
                suscrito por el (la) titular al amparo del mismo.
              </li>
              <li>
                Alguna porción de sus datos sea procesada con el fin de auditar
                el debido cumplimiento de legislación nacional o extrajera que
                le sea aplicable a Beloz y siempre bajo una relación contractual
                que obligue a dicho tercero a mantener la confidencialidad de
                sus datos y el tercero receptor asuma las mismas
                responsabilidades que tiene Beloz.
              </li>
              <br />
              Beloz, se compromete a no transferir su información personal a
              terceros sin su consentimiento, salvo las excepciones previstas en
              la Ley Federal de Protección de Datos Personales en Posesión de
              los Particulares y su Reglamento, así como a realizar esta
              transferencia en los términos que fijan dichos ordenamientos. Si
              usted no manifiesta su oposición para que sus datos personales
              puedan ser transferidos, se entenderá que ha otorgado su
              consentimiento.
              <br />
              En caso de que no obtengamos una oposición expresa de su parte
              para que sus datos personales sean transferidos en la forma y
              términos antes descrito, entenderemos que ha otorgado su
              consentimiento en forma tácita para ello.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">6. EJERCICIO DE DERECHOS</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              En los términos permitidos por la normatividad aplicable, usted
              podrá acceder, rectificar y cancelar sus datos personales,
              oponerse al tratamiento de estos, así como revocar en cualquier
              momento el consentimiento que en su caso nos haya otorgado, a
              través del correo electrónico: soporte@amiloz.com.[a][b]
              <br />
              <br />
              Su solicitud deberá contener
              <br />
              <li>Nombre completo</li>
              <li>
                Copia simple o copia digital de identificación oficial para
                acreditar la identidad. (IFE, pasaporte, cédula profesional)
              </li>
              <li>
                Representación: En caso de actuar en representación de un menor,
                se deberá presentar acta de nacimiento o documento oficial donde
                se acredite la personalidad del padre o tutor; en caso de actuar
                en representación de un tercero mayor de edad, deberá adjuntar
                el poder donde se acrediten sus facultades para actuar en
                representación de éste.
              </li>
              <li>
                Descripción breve de lo que solicita, ya sea el acceso a sus
                datos, rectificación o cancelación de éstos, oposición al
                tratamiento o algunas finalidades del tratamiento o revocación
                del consentimiento.
              </li>
              <li>
                Para rectificar su nombre o domicilio, anexar copia de
                justificativo en caso de ser distinto a la identificación
                oficial presentada para acreditar la identidad.
              </li>
              <li>
                El plazo de respuesta será de 20 días hábiles con base en el
                calendario oficial publicado en el Diario Oficial de la
                Federación.
              </li>
              <br />
              La respuesta a su solicitud será enviada a través del mismo medio
              por el cual fue recibida, es decir, vía correo electrónico.
              <br />
              <br />
              En caso de no recibir respuesta de nuestra parte o si ella le
              resultara insatisfactoria, usted cuenta con un plazo de 15 días
              hábiles para acudir al INAI y solicitar que se inicie un
              procedimiento de protección de derechos.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">
                7. MECANISMOS PARA LA LIMITACIÓN DEL USO Y DIVULGACIÓN DE LOS
                DATOS Y LA REVOCACIÓN DEL CONSENTIMIENTO
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              Usted podrá limitar el uso, divulgación de sus datos personales u
              oponerse al tratamiento de éstos para finalidades secundarias, a
              través del correo electrónico: soporte@amiloz.com.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">8. REVOCACIÓN</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              En todo momento, usted podrá revocar el consentimiento que nos ha
              otorgado para el tratamiento de sus datos personales, a fin de que
              dejemos de hacer uso de los mismos. Para ello es necesario que
              presente su petición por escrito conforme al FORMATO PARA EJERCER
              LOS DERECHOS ARCO Y REVOCACIÓN DE CONSENTIMIENTO, que se encuentra
              disponible en nuestra página web a través de la presentación de la
              solicitud respectiva que deberá ser enviada al correo electrónico
              soporte@amiloz.com.
              <br />
              Sin embargo, es importante que tenga en cuenta que no en todos los
              casos podremos atender su solicitud o concluir el uso de forma
              inmediata, ya que es posible que por alguna obligación legal
              queramos seguir tratando sus datos personales En un plazo máximo
              de veinte días hábiles atenderemos su petición y le informaremos
              sobre la procedencia de la misma.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">9. MODIFICACIONES</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              Beloz se reserva el derecho de modificar o actualizar el presente
              Aviso de Privacidad en cualquier momento, para la atención de
              novedades legislativas, políticas internas o nuevos requerimientos
              para la prestación u ofrecimiento de sus servicios o productos.
              <br />
              <br />
              Estas modificaciones estarán disponibles al público a través de
              los siguientes medios:
              <br />
              (i) Anuncios visibles en nuestras oficinas.
              <br />
              (ii) En nuestra página de internet.
              <br />
              (iii) O se las haremos llegar al último correo electrónico que nos
              haya proporcionado.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">10. CONSENTIMIENTO</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              Usted manifiesta que ha leído, entendido y acordado los términos
              expuestos en el presente Aviso de Privacidad, por lo que otorga su
              consentimiento respecto al tratamiento de sus datos personales en
              los términos y condiciones aquí señalados.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">11. COOKIES</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              Con el fin de ofrecer un servicio más personalizado y responsable,
              beloz almacena información sobre cómo utiliza este sitio web. Esto
              se hace mediante un pequeño archivo de texto llamado cookie. Las
              cookies contienen pequeñas cantidades de información y se
              descargan en su ordenador u otro dispositivo por un servidor de
              este sitio web. Su navegador envía estas cookies de nuevo a este
              sitio web en cada visita posterior para que pueda reconocerle y
              recordar cosas como las preferencias de usuario.
              <br />
              <br />
              Algunas de las cookies que utilizamos son necesarias para que
              usted pueda navegar en el sitio web, así como hacer uso de sus
              funciones, tales como el acceso a áreas seguras con contenido
              especial para usuarios registrados. También utilizamos las
              denominadas cookies funcionales con la finalidad de registrar
              información sobre las opciones que haya elegido previamente, lo
              cual nos permite personalizar el sitio para nuestros usuarios; por
              ejemplo, recordar su idioma, oferta académica consultada, o que ya
              ha completado una encuesta. Esta información es anónima y no se
              utiliza para ningún otro propósito. También, nosotros, o nuestros
              proveedores de servicios, utilizamos los servicios de análisis
              para ayudarnos a entender la eficacia de nuestro contenido y saber
              qué es lo que interesa a nuestros usuarios, con la finalidad de
              mejorar el funcionamiento de este sitio web. Además, hacemos de su
              conocimiento que utilizamos web beacons o píxeles de seguimiento
              para contar el número de visitantes, así como las denominadas
              cookies de rendimiento para realizar el seguimiento de cuántos
              usuarios, de manera individual, tienen acceso a esta página web y
              con qué frecuencia. Esta información es utilizada únicamente con
              fines estadísticos y no se tiene como propósito utilizarla para
              identificar personalmente a ningún usuario. Esta página Web no
              utiliza las denominadas Targeting Cookies para entregar publicidad
              específica dirigida a los visitantes de este sitio web.
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              [a] Las imágenes, ilustraciones, logotipos, fotografías, software,
              videos, textos, diseño gráfico y cualquier otro contenido que se
              encuentre en el correo electrónico y/o archivos electrónicos que
              beloz le proporcione, se encuentran protegidos por derechos de
              propiedad intelectual y pertenecen a beloz o a terceros que le han
              autorizado su uso a beloz. Cualquier uso no autorizado de dichos
              derechos de propiedad intelectual constituirá una violación a los
              presentes Términos y Condiciones y a la legislación aplicable.
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              [b] Beloz no se hace responsable de la veracidad de los datos
              personales proporcionados por el titular ni de las consecuencias
              que de ello se deriven. Por tanto, cualquier daño o perjuicio que
              se pudiera generar por la entrega de datos falsos, inexactos o
              incompletos, será responsabilidad exclusiva del titular.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Help />
    </section>
  );
}
