import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import {
  Article,
  Blog,
  Home,
  NotFound,
  PrivacyPolicy,
  TermsAndConditions,
} from "./pages";
import { Navbar, Footer } from "./components";
import { getAllArticlesService } from "./services/blog.services";
import { getExperimentEvaluationService } from "./services/experiments.services";

function App() {
  const [blogs, setBlogs] = useState([]);
  const [publicIP, setPublicIP] = useState(null);
  const [curpFillType, setCurpFillType] = useState(null);
  const [isHomePage, setIsHomePage] = useState(false);

  const getBlogEntries = async () => {
    try {
      const { success, data } = await getAllArticlesService(1, 3);
      if (success) {
        setBlogs(data.articles);
      }
    } catch (error) {
      console.log("getBlogEntries -> error: ", error);
    }
  };

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then(async (data) => {
        const fillType = await getExperimentEvaluationService(
          "web_curp_fill",
          data.ip,
          "autocompleted"
        );
        setCurpFillType(fillType);
        setPublicIP(data.ip);
      })
      .catch((error) => console.error(error))
      .finally(() => getBlogEntries());
  }, []);

  return (
    <Router>
      <Navbar isHomePage={isHomePage} publicIP={publicIP} curpFillType={curpFillType} />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              publicIP={publicIP}
              curpFillType={curpFillType}
              blogs={blogs}
              setIsHomePage={setIsHomePage}
            />
          } />
        <Route path="/aviso-de-privacidad" element={<PrivacyPolicy />} />
        <Route path="/politica-de-privacidad" element={<PrivacyPolicy />} />
        <Route
          path="/terminos-y-condiciones"
          element={<TermsAndConditions />} />
        <Route
          path="/terminos-y-condiciones"
          element={<TermsAndConditions />}
        />
        <Route
          path="/blog"
          element={
            <Blog
            /* blogs={blogs.slice(1, blogs.length)}
            latestBlog={blogs.slice(0, 1)} */
            />
          }
        />
        <Route path="/blog/:postName" element={<Article blogs={blogs} />} />
        <Route path="/article" element={<Article blogs={blogs} />} />
        <Route path="/article/:postName" element={<Article blogs={blogs} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer publicIP={publicIP} curpFillType={curpFillType}/>
    </Router>
  );
}

export default App;
