import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  post: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  img: {
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  bullet: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.text.hint,
  },
  name: {
    lineHeight: 1,
  },
}));

export default function Blog({ blogs, showAll = true }) {
  const classes = useStyles();

  return (
    <section style={{ backgroundColor: "rgb(242, 242, 242)" }}>
      <Container maxWidth="lg">
        <Box py={10}>
          <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              style={{ fontWeight: 700 }}
            >
              Préstamos Personales e Información Financiera	
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              color="textSecondary"
              paragraph={true}
            >
              Aprende a manejar tu dinero y Beloz te provee los recursos para alcanzar tus metas.	
            </Typography>
            
            <Grid container spacing={3}>
              {blogs.map((blog) => (
                <Grid item xs={12} md={4} className={classes.post} key={blog.id}>
                  <img src={blog.img} alt="" className={classes.img} />
                  <Box mt={2} mb={2}>
                    <Box mb={1}>
                      <Typography
                        variant="overline"
                        component="span"
                        color="textSecondary"
                      >
                        {moment(blog.createdAt).format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                  <Link href={`/blog/${blog.urlTitle}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="h5" component="h2">
                        {blog.title}
                      </Typography>
                    </Link>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    paragraph={true}
                    color="textSecondary"
                  >
                    {blog.intro}
                  </Typography>
                  <Box mt={3} display="flex" alignItems="center">
                    <Avatar alt="" src={"./images/blog/author.png"} />
                    <Box ml={2} textAlign="left">
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        className={classes.name}
                      >
                        { blog?.author ? blog.author : 'Andrés Taurián' }
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="h3"
                        color="textSecondary"
                      >
                        { blog?.role ? blog.role : 'Especialista en Redacción de Contenido Financiero' }
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}            
            </Grid>
            {/* {showAll && (
              <Box mt={6} textAlign="center">
                <Button variant="outlined" color="inherit" href="/blog">
                  Ver todas las entradas
                </Button>
              </Box>
            )} */}
        </Box>
      </Container>
    </section>
  );
}
