import {useEffect} from "react";
import {Banner, Benefits, FAQ, Help, LandingBlog, PoweredBy, Process,} from "../components";
import {Helmet} from "react-helmet";

function Home({blogs, publicIP, curpFillType, setIsHomePage}) {

    useEffect(() => {
        setIsHomePage(true);
        return () => {
            setIsHomePage(false);
        };
    }, [setIsHomePage]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Préstamos Personales En Línea, Al Instante y Seguros | Beloz </title>
                <meta name="description"
                      content="Préstamos en línea personales, grupales, para emprendedores y más. Tu primer préstamo es sin interés. Mejora tu historial crediticio. Renovables y seguros."/>
            </Helmet>
            {/* <Header handleOpen={handleOpen} />
      <Download />
      <WUs />
      <Testimonials /> */}
            <Banner publicIP={publicIP} curpFillType={curpFillType}/>
            <Benefits/>
            <Process publicIP={publicIP} curpFillType={curpFillType}/>
            {blogs.length > 0 && <LandingBlog blogs={blogs}/>}
            <PoweredBy/>
            <FAQ/>
            <Help/>
        </>
    );
}

export default Home;
