import React, { useEffect } from "react";

const FacebookPixel = () => {
    useEffect(() => {
        // Ensure the script runs only on the client side
        const script = document.createElement("script");

        // Inline script content
        script.innerHTML = `
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "3528950814101941");
      fbq("track", "PageView");
    `;

        // Append the script to the document body
        document.body.appendChild(script);

        // Cleanup the script on component unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []); // Run only once when the component is mounted

    return null; // No UI for this component
};

export default FacebookPixel;
