import {useEffect, useState} from "react";
import {Button, Checkbox, FormControlLabel, Link, Typography,} from "@material-ui/core";
import {v4 as uuidv4} from "uuid";

import {CodeInput, UserPhoneInput} from "../helpers";
import {STATE_LETTERS} from "../../utils/constants";
import {createLandingApplication} from "../../services/application.services";
import {toast} from "react-toastify";
import {useAnalytics} from "../../hooks/useAnalytics";
import useStyles from "../NewLoan.styles";

function Acceptance({
                        formData,
                        handleNext,
                        amilozBEToken,
                        setAmilozBEToken,
                        marketing_metadata = null,
                        email,
                        phone,
                        setPhone,
                        attributionChannel,
                        setApplication,
                        publicIP,
                        referrer = null,
                        curpFillType,
                        password,
                        credoAppService,
                    }) {
    const analytics = useAnalytics();
    const classes = useStyles();
    const [isCodeValid, setIsCodeValid] = useState(false);
    const [privacyAccepted, setPrivacyAccepted] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const [location, setLocation] = useState(null);
    const [codeSent, setCodeSent] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (amilozBEToken) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const {latitude, longitude} = position.coords;
                        setLocation({latitude, longitude});
                    },
                    (error) => {
                        console.error("Error al obtener la geolocalización", error);
                    }
                );
            } else {
                console.error("La geolocalización no es soportada por este navegador");
            }
        }
    }, [amilozBEToken]);

    const createApplication = async () => {
        try {
            setLoading(true);

            try {
                await credoAppService.stopTrackingAndComplete(uuidv4());
            } catch (error) {
                console.log("Error starting tracking", error);
            } finally {
                console.log("pass");
            }

            const tmpFD = {...formData};
            tmpFD.addressInfo.addressLine1 = `${tmpFD?.addressInfo?.addressLine1} ${tmpFD?.addressInfo?.extNumber}`;
            delete tmpFD?.addressInfo?.extNumber;
            if (tmpFD?.addressInfo?.addressLine2 == "")
                delete tmpFD?.addressInfo?.addressLine2;
            tmpFD.addressInfo.state =
                STATE_LETTERS[tmpFD?.addressInfo?.state.toUpperCase()];
            const deviceInfo = {
                ip: publicIP,
                contacts: [],
                location,
            };

            const {success, applicationId} = await createLandingApplication(
                amilozBEToken,
                {...formData, phone, email, foundByAnswer: attributionChannel},
                referrer
                    ? {
                        channel: "paid",
                        source: "referer",
                        landing: "na",
                        campaign: "na",
                    }
                    : marketing_metadata,
                deviceInfo,
                referrer,
                curpFillType
            );

            analytics.sendEvent({
                event: "Contact",
                event_category: "FormSubmission",
            });
            analytics.sendEvent({
                event: "CompleteRegistration",
                event_category: "FormSubmission",
            });
            window.gtag("event", "conversion", {
                send_to: "AW-11111897320/0eNnCKS9vZcYEOixyLIp",
            });

            if (!success) {
                toast.error(
                    "No pudimos crear tu solicitud. Por favor, intenta de nuevo.",
                    {
                        position: "top-center",
                        theme: "light",
                        autoClose: false,
                    }
                );
            } else {
                setApplication(applicationId);
                handleNext();
            }
        } catch (error) {
            console.log("createApplication error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className={classes.sectionFlexColumn}>
            <img
                src={
                    amilozBEToken
                        ? "/images/application/otpApproved.svg"
                        : "/images/application/otp.svg"
                }
                className={classes.imgCenter}
                alt="OTP"
            />
            {!isCodeValid ? (
                !codeSent ? (
                    <UserPhoneInput
                        phone={phone}
                        setPhone={setPhone}
                        setCodeSent={setCodeSent}
                        password={password}
                        setAmilozBEToken={setAmilozBEToken}
                    />
                ) : (
                    <CodeInput
                        phone={phone}
                        amilozBEToken={amilozBEToken}
                        setAmilozBEToken={setAmilozBEToken}
                        setCodeSent={setCodeSent}
                        setPhone={setPhone}
                        isCodeValid={isCodeValid}
                        setIsCodeValid={setIsCodeValid}
                    />
                )
            ) : (
                <Typography variant="subtitle2" className={classes.textCenter}>
                    Celular verificado exitosamente
                </Typography>
            )}
            <div className={classes.scrollableBox}>
                <Typography variant="body1" component="p">
                    Autorizo expresamente a Beloz, para que lleve a cabo investigaciones
                    sobre mi comportamiento en las Sociedades de Información Crediticia
                    (SIC) que estime conveniente.
                    <br/>
                    <br/>
                    De la misma forma, autorizo expresamente a Beloz para que lleve a cabo
                    investigaciones sobre mi comportamiento en el Historial Laboral del
                    IMSS.
                    <br/>
                    <br/>
                    Conozco la naturaleza y alcance de la información que se solicitará,
                    del uso que se le dará y que se podrán realizar consultas periódicas
                    de mi historial crediticio.
                    <br/>
                    <br/>
                    Consiento que esta autorización tenga una vigencia de 3 años contados
                    a partir de hoy, y en su caso mientras mantengamos relación juridica.
                    Acepto que este documento quede bajo propiedad de Beloz para efectos
                    de control y cumplimiento del articulo 28 de la LRSIC.
                </Typography>
            </div>
            <section>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="terms-accepted-checkbox"
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body1" component="p">
                            Acepto
                            <Link
                                variant="body1"
                                href="/terminos-y-condiciones"
                                target="_blank"
                            >
                                {" "}
                                Términos y Condiciones{" "}
                            </Link>
                            y cláusula de medios electrónicos tales como NIP.
                        </Typography>
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            id="privacy-accepted-checkbox"
                            checked={privacyAccepted}
                            onChange={(e) => setPrivacyAccepted(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body1" component="p">
                            Acepto{" "}
                            <Link
                                variant="body1"
                                href="/politica-de-privacidad"
                                target="_blank"
                            >
                                Política de privacidad
                            </Link>
                            .
                        </Typography>
                    }
                />
            </section>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.primaryAction}
                disabled={
                    !privacyAccepted || !termsAccepted || amilozBEToken == null || loading
                }
                onClick={createApplication}
            >
                Solicitar
            </Button>
        </section>
    );
}

export default Acceptance;
