import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import {
  createReprocessUnderwriting,
  createUnderwritingApplication,
  updateReprocessUnderwriting,
} from "../../services/evaluation.services";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Button, Typography } from "@material-ui/core";
import useStyles from "../NewLoan.styles";
import FacebookPixel from "../../components/pixels/MetaPixelAnalytics.jsx";
import TwitterPixel from "../../components/pixels/TwitterPixelAnalytics.jsx";

function Evaluation({
  amilozBEToken,
  application,
  formData,
  email,
  phone,
  publicIP,
  evaluated,
  setEvaluated,
  isApproved,
  setIsApproved,
  isPending,
  setIsPending,
}) {
  const classes = useStyles();
  const tokenData = jwtDecode(amilozBEToken);

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const evaluate_underwriting_p1 = async () => {
    let eventSourceTimeout; // Declare at the top of the function

    try {
      const p1_body = {
        userId: tokenData?.id,
        type: "partial",
        applicationId: application,
        flowName: "internal_policy_p1",
        request: {
          user: {
            userId: tokenData?.id,
            curp: formData?.personalInfo?.curp,
            phone: `+${phone}`,
            email,
          },
          deviceData: {
            contacts: {
              total: 666,
              unique: 666,
            },
          },
        },
      };
      const { data: underwritingData } = await createUnderwritingApplication(
        p1_body
      );
      if (!underwritingData?.approved) {
        setIsApproved(false);
        setEvaluated(true);
        return;
      }

      const p2_body = {
        userId: tokenData?.id,
        type: "complete",
        applicationId: application,
        flowName: "internal_policy_p2",
        request: {
          user: {
            userId: tokenData?.id,
            curp: formData?.personalInfo?.curp,
            phone: `+${phone}`,
            email,
          },
          deviceData: {
            ip: publicIP,
          },
          service_responses: {
            "income-request":
              underwritingData?.service_responses["income-request"] ?? "",
          },
        },
      };
      await createReprocessUnderwriting(tokenData?.id, application, {
        ...p2_body,
      });

      const eventSource = new EventSource(
        `${
          import.meta.env.VITE_APP_UNDERWRITING_ENDPOINT
        }/orchestrator/income/${
          underwritingData?.service_responses["income-request"] ?? ""
        }`
      );

      eventSourceTimeout = setTimeout(() => {
        if (eventSource.readyState !== EventSource.CLOSED) {
          eventSource.close();
          setIsPending(true);
          setEvaluated(true);
          console.log("EventSource closed due to timeout.");
        }
      }, 120000);

      eventSource.onmessage = async function (event) {
        if (event.data === "true") {
          clearTimeout(eventSourceTimeout);
          await evaluate_underwriting_p2(p2_body);
          eventSource.close();
        }
      };
    } catch (error) {
      console.log("Error evaluating underwriting p1", error);
      clearTimeout(eventSourceTimeout);
    }
  };
  const evaluate_underwriting_p2 = async (requestBody) => {
    try {
      const { data: underwritingData } = await createUnderwritingApplication(
        requestBody
      );
      await updateReprocessUnderwriting(tokenData?.id, application, true);
      if (!underwritingData?.approved) {
        setIsApproved(false);
        setEvaluated(true);
        return;
      }
      setIsApproved(true);
      setEvaluated(true);
    } catch (error) {
      console.log("Error evaluating underwriting p2", error);
    }
  };

  useEffect(() => {
    if (!evaluated) {
      evaluate_underwriting_p1();
    }
  }, [evaluated]);

  return (
    <section className={classes.evaluation}>
      {!evaluated ? (
        <>
          <img src="/images/application/loading.gif" alt="Loading" />
          <Typography variant="subtitle1" className={classes.textBlock}>
            Este proceso puede tomar hasta 5 minutos. Puedes esperar aquí para
            conocer el estatus de tu solicitud, de otra forma te enviaremos un
            Whatsapp en unos minutos para compartírtelo.
            <br />
            ¡Gracias por tu paciencia!
          </Typography>
          <FacebookPixel/>
          <TwitterPixel/>
        </>
      ) : (
        <>
          <img
            src="/images/application/confirmation.svg"
            alt="Confirmation"
            className={classes.imageHalfWidth}
          />
          {isPending ? (
            <Alert severity="info">
              <AlertTitle>Pendiente</AlertTitle>
              Parece que nos está tomando un poco más de tiempo resolver tu
              solicitud. En unos minutos te enviaremos por Whatsapp el resultado
              de tu aplicación. ¡Gracias por tu paciencia!
            </Alert>
          ) : isApproved ? (
            <Alert
              action={
                isMobile ? (
                  <Button
                    href="https://appbeloz.page.link/download"
                    target="_blank"
                    color="inherit"
                    size="small"
                  >
                    Descargar
                  </Button>
                ) : null
              }
            >
              <AlertTitle>¡Súper!</AlertTitle>
              ¡Fuiste aprobado para recibir un préstamo con Beloz! Descarga la
              app ya y termina tu aplicación.
            </Alert>
          ) : (
            <Alert severity="error">
              <AlertTitle>¡Oh no!</AlertTitle>
              Desafortunadamente no podemos proceder con tu aprobación del
              préstamo solicitado. Puedes reestructurar tu deuda y volver a
              aplicar. <strong>¡Esperamos saber de tí pronto!</strong>
            </Alert>
          )}
        </>
      )}
    </section>
  );
}

export default Evaluation;
