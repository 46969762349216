import React, { useEffect } from "react";

const TwitterPixel = () => {
    useEffect(() => {
        // Dynamically create and inject the script for Twitter tracking
        const script = document.createElement("script");

        // Inline script content
        script.innerHTML = `
      !function(e,t,n,s,u,a) {
        e.twq || (s = e.twq = function() {
          s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
        }, s.version = '1.1', s.queue = [],
        u = t.createElement(n), u.async = !0, u.src = 'https://static.ads-twitter.com/uwt.js',
        a = t.getElementsByTagName(n)[0], a.parentNode.insertBefore(u, a))
      }(window, document, 'script');
      
      twq('config', 'ozuev');
      twq('event', 'tw-ozuev-ozuex', {});
    `;

        // Append the script to the DOM
        document.body.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []); // Run only on mount (empty dependency array)

    return null; // The component doesn't render any visible UI
};

export default TwitterPixel;
