import {alpha, makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RoomIcon from "@material-ui/icons/Room";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {Avatar, Button, Grid} from "@material-ui/core";
import {useDynamicLink} from "../hooks/useDynamicLink";
import {NewLoanModal} from "../modals";

const useStyles = makeStyles((theme) => ({
    rootBox: {
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
        },
    },
    primaryAction: {
        backgroundColor: "#FACE07",
        padding: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: "25%",
        "&:hover": {
            backgroundColor: "black",
            boxShadow: "0px 0px 5px #FACE07",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginRight: theme.spacing(0),
            marginBottom: theme.spacing(2),
        },
    },
    footerNav: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        marginBottom: theme.spacing(0),
        [theme.breakpoints.down("md")]: {
            width: "100%",
            marginTop: theme.spacing(3),
        },
    },
    footerLink: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(2),
        },
    },
    contactLink: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(2),
        },
    },
    footerCopy: {
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderTopColor: theme.palette.background.emphasis,
    },
    copy: {
        [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    subCopy: {
        [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    icons: {
        [theme.breakpoints.down("md")]: {
            marginRight: "auto",
        },
    },
    contactWrapper: {
        backgroundColor: alpha("#EAEAEA", 0.2),
        color: "white",
    },
    iconWrapper: {
        backgroundColor: alpha("#EAEAEA", 0.6),
    },
    logo: {
        height: 36,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
}));

export default function Footer({publicIP, curpFillType}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const downloadAppDynamicLink = useDynamicLink();

    const handleOpen = () => {
        setOpen(true);
    };

    const content = {
        brand: {image: "./images/footer/footer.png", width: 110},
        subCopy: "Contacto y soporte",
        copy: `© ${new Date().getFullYear()} Amiloz. Derechos reservados.`,
        link1: "Aviso de privacidad",
        link2: "Términos y condiciones",
    };

    let brand;

    if (content.brand.image) {
        brand = (
            <img src={content.brand.image} alt="" width={content.brand.width}/>
        );
    } else {
        brand = content.brand.text || "";
    }

    return (
        <footer style={{backgroundColor: "black", color: "white"}}>
            <Container maxWidth="lg">
                <Box
                    pt={6}
                    pb={2}
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    className={classes.rootBox}
                >
                    <Link href="/" color="inherit" underline="none">
                        {brand}
                    </Link>
                    <Box component="nav" ml="auto" className={classes.footerNav}>
                        <Link
                            href="/aviso-de-privacidad"
                            variant="body1"
                            color="inherit"
                            className={classes.footerLink}
                        >
                            {content["link1"]}
                        </Link>
                        <Link
                            href="/terminos-y-condiciones"
                            variant="body1"
                            color="inherit"
                            className={classes.footerLink}
                        >
                            {content["link2"]}
                        </Link>
                    </Box>
                </Box>
                <Box
                    pb={2}
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.rootBox}
                >
                    <Button
                        id="aplicar-footer"
                        variant="contained"
                        color="primary"
                        className={classes.primaryAction}
                        onClick={handleOpen}
                    >
                        Aplicar
                    </Button>
                    <Box component="nav" ml="auto" className={classes.footerNav}>
                        <Link
                            href={downloadAppDynamicLink.android}
                            target="_blank"
                        >
                            <img
                                src="./images/download/playstore.png"
                                alt="Google Play Store"
                                className={classes.logo}
                            />
                        </Link>
                        <Link
                            href={downloadAppDynamicLink.ios}
                            target="_blank"
                        >
                            <img
                                src="./images/download/appstore.png"
                                alt="App Store"
                                className={classes.logo}
                            />
                        </Link>
                    </Box>
                </Box>
                <Box pt={1} pb={6} display="flex" flexWrap="wrap" alignItems="center">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <div className={classes.contactWrapper}>
                                <Box textAlign="center" py={4}>
                                    <Box display="flex" justifyContent="center" mb={3}>
                                        <Avatar className={classes.iconWrapper}>
                                            <RoomIcon color="inherit" fontSize="small"/>
                                        </Avatar>
                                    </Box>
                                    <Typography variant="h6" gutterBottom={true}>
                                        Dirección
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        Popocatépetl 510 Int. A003
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        Benito Juárez, CP 03330, CDMX
                                    </Typography>
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className={classes.contactWrapper}>
                                <Box textAlign="center" py={4}>
                                    <Box display="flex" justifyContent="center" mb={3}>
                                        <Avatar className={classes.iconWrapper}>
                                            <EmailIcon color="inherit" fontSize="small"/>
                                        </Avatar>
                                    </Box>
                                    <Typography variant="h6" gutterBottom={true}>
                                        Email
                                    </Typography>
                                    <Link
                                        href="mailto:soporte@amiloz.com"
                                        variant="body2"
                                        color="inherit"
                                        className={classes.contactLink}
                                    >
                                        soporte@amiloz.com
                                    </Link>
                                    <br/>
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className={classes.contactWrapper}>
                                <Box textAlign="center" py={4}>
                                    <Box display="flex" justifyContent="center" mb={3}>
                                        <Avatar className={classes.iconWrapper}>
                                            <WhatsAppIcon color="inherit" fontSize="small"/>
                                        </Avatar>
                                    </Box>
                                    <Typography variant="h6" gutterBottom={true}>
                                        WhatsApp
                                    </Typography>
                                    <Link
                                        href="https://api.whatsapp.com/send?phone=525592251996"
                                        variant="body2"
                                        color="inherit"
                                        className={classes.contactLink}
                                    >
                                        +525592251996
                                    </Link>
                                    <br/>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    pt={1}
                    pb={1}
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    className={classes.footerCopy}
                >
                    <Typography
                        color="inherit"
                        component="p"
                        variant="caption"
                        gutterBottom={false}
                        className={classes.copy}
                    >
                        {content["copy"]}
                    </Typography>
                    <Box ml="auto" className={classes.icons}>
                        <IconButton
                            href="https://www.facebook.com/belozmx"
                            target="_blank"
                            color="inherit"
                            aria-label="Instagram"
                        >
                            <InstagramIcon/>
                        </IconButton>
                        <IconButton
                            href="https://www.instagram.com/belozmx"
                            target="_blank"
                            color="inherit"
                            aria-label="Facebook"
                        >
                            <FacebookIcon/>
                        </IconButton>
                        <IconButton
                            href="https://www.tiktok.com/@belozmx"
                            target="_blank"
                            color="inherit"
                            aria-label="Tiktok"
                        >
                            <TwitterIcon/>
                        </IconButton>
                        <IconButton
                            href="https://www.linkedin.com/company/beloz"
                            target="_blank"
                            color="inherit"
                            aria-label="LinkedIn"
                        >
                            <LinkedInIcon/>
                        </IconButton>
                    </Box>
                </Box>
                <Box
                    pt={1}
                    pb={2}
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    className={classes.footerCopy}
                >
                    <Typography
                        style={{color: "#797979"}}
                        component="p"
                        variant="caption"
                    >
                        Desde $2,750 MXN hasta $7,250 MXN. Periodo mínimo y máximo de
                        repago: desde 91 días (13 semanas) hasta 147 días (21 semanas), de
                        acuerdo a la solicitud del cliente. APR (Tasa de interés anual)
                        máximo, que incluye tasa de interés y todos los costos anuales:
                        789%. CAT Promedio con IVA informativo: 29476% (calculado a Agosto
                        de 2023). Ejemplo representativo del costo total del crédito
                        incluyendo comisiones e intereses: Por $5,750 MXN a un plazo de 140
                        días (20 semanas) pagarías un total de $9,614 (incluyendo IVA),
                        correspondiente a $458.00 MXN semanalmente. Comisión por apertura
                        puede aplicar, descrita en tu contrato de crédito simple aplicable.
                        Sin comisión por estudio o por cancelación anticipada. Las
                        cantidades pueden variar dependiendo del plazo y tu perfil. El monto
                        que se autoriza en el crédito depende de tu historial crediticio.
                        Incumplir tus pagos te puede generar comisiones ($50.00 MXN por día)
                        moratorias y esto puede afectar tu historial crediticio.
                        <br/>
                        <br/>* 0% de interés en el primer periodo del préstamo, en que si
                        vas al corriente en tus pagos, y aplicando términos y condiciones,
                        podrás renovar para continuar tu trayectoria de crecimiento
                        financiero con Beloz.
                    </Typography>
                </Box>
                <NewLoanModal
                    open={open}
                    setOpen={setOpen}
                    publicIP={publicIP}
                    curpFillType={curpFillType}
                />
            </Container>
        </footer>
    );
}
