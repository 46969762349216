import { useParams } from "react-router-dom";
import { Entry, Footer, LandingBlog, Navbar } from "../components";
import { Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronLeft } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(8),
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
    },
  },
}));

function Article({ blogs }) {
  const classes = useStyles(); // Asegúrate de descomentar esta línea
  const { postName } = useParams(); // Asegúrate de usar el parámetro correctamente

  return (
    <>
      <Box className={classes.content}>
        <ChevronLeft />
        <Link href="/blog">Volver a los post</Link>
      </Box>
      <Entry postName={postName} />
      {blogs.length > 0 && <LandingBlog blogs={blogs} />}
    </>
  );
}

export default Article;
